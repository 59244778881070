import React, { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet';

import logo from "../images/icon.png";


import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

import {Link as GatsbyLink} from 'gatsby';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  headerImage: {
    maxHeight: '56px',
    paddingTop: '10px'
  },
}));


export const PageContainer = (props) => {
  const classes = useStyles();


  return (
    <React.Fragment>
        <Helmet>
            <meta name="twitter:site" content="@rave_texas" />
            <meta name="twitter:creator" content="@rave_texas" />
        </Helmet>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          {/* <CameraIcon className={classes.icon} /> */}
          <Typography
            variant="h6"
            noWrap
            component="div"
            className={classes.headerImageContainer}
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            <Link to="/" component={GatsbyLink}><img src={logo} alt="ravetexas" className={classes.headerImage} /></Link>
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {props.children}
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Any issues or want your event posted, contact <Link href="mailto:james@drakeapps.com">james@drakeapps.com</Link>
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          made with ❤️ by <Link href="https://jamesdrakewilson.com">james wilson</Link>
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          We try to ensure all of our data is as accurate as possible, but always verify any event details with the venue and assocaited websites
        </Typography>
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
};
