
import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

import { Link as GatsbyLink } from "gatsby";

import { GatsbyImage } from "gatsby-plugin-image";

import slugify from 'slugify';


import LazyLoad from 'react-lazyload';

const config = {};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    // paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));


export const hourOutput = (date) => {
  const d = new Date(date);
  return `${d.toLocaleString('en-US', { hour: 'numeric', hour12: true })}`;
};

export const formatDate = (date, options) => {
  let d = new Date(date);
  return d.toLocaleDateString('en-US', options);
};

export const dateString = (date) => {
  return `${formatDate(date, {weekday: 'long'})}, ${formatDate(date, {month: 'long'})} ${formatDate(date, {day: 'numeric'})}`;
};

export const eventURL = (event) => {
  return `/event/${event.slug}`;
};


export const EventTile = (props) => {
    const classes = useStyles();
    const event = props.event;
    return (
        <Grid item key={props.event.id} xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            {(event.cover) ? <Link to={eventURL(event)} component={GatsbyLink}><LazyLoad height={160}><CardMedia className={classes.cardMedia}><GatsbyImage
            //   className={classes.cardMedia}
              image={event.cover.localFile.childImageSharp.gatsbyImageData}
              title={event.title}
              alt={event.title}
            /></CardMedia></LazyLoad></Link> : <></>}
            <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h5" component="h2">
                <Link to={eventURL(event)} component={GatsbyLink}>{event.title}</Link>
              </Typography>
              {/* <Typography>
                {event.description}
              </Typography> */}
              <Typography>
                {event.venue.name}
              </Typography>
              <Typography>
                {`${hourOutput(event.start)} - ${hourOutput(event.end)}`}
              </Typography>
            </CardContent>
            {/* <CardActions>
              <Button size="small" color="primary">
                View
              </Button>
              <Button size="small" color="primary">
                Edit
              </Button>
            </CardActions> */}
          </Card>
        </Grid>
      );
};



// export {EventTile};